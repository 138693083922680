import './Drawer.scss';
import { useEffect, useState } from 'react';
import { isTelegramWebview } from '../../data/device';
import { assets } from '../../assets/assets';
import { useAppUpdates, app } from '../../data/Controllers/AppController';
import { DrawerPowerUpInfo } from './drawers/DrawerPowerUpInfo';
import { DrawerPowerUpGiftExpired } from './drawers/DrawerPowerUpGiftExpired';
import { DrawerPowerUpEarn } from './drawers/DrawerPowerUpEarn';
import { DrawerGeneric } from './drawers/DrawerGeneric';
import { DrawerPowerUpCardPromo } from './drawers/DrawerPowerUpCardPromo';
import { DrawerPowerUpCardGiftReceived } from './drawers/DrawerPowerUpCardGiftReceived';
import { DrawerCriticalError } from './drawers/DrawerCriticalError';
import { DrawerDailyAirdrop } from './drawers/DrawerDailyAirdrop';
import { DrawerDailyReward } from './drawers/DrawerDailyReward';
import { DrawerEarnCheck } from './drawers/DrawerEarnCheck';
import { DrawerInviteFriend } from './drawers/DrawerInviteFriend';
import { DrawerLeaderboard } from './drawers/DrawerLeaderboard';
import { DrawerOutOfEnergy } from './drawers/DrawerOutOfEnergy';
import { DrawerRewardsSummary } from './drawers/DrawerRewardsSummary';
import { DrawerWelcome } from './drawers/DrawerWelcome';
import { DrawerPowerUp } from './drawers/DrawerPowerUp/DrawerPowerUp';
import { DrawerFriendsDetails } from './drawers/DrawerFriendsDetails';
import { CreateTeamDetails } from '../pages/TeamPages/CreateTeamDetails';
import { DrawerMultipleGifts } from './drawers/DrawerMultipleGifts';
import { DrawerQuestCheck } from '../pages/EarnPage/DrawerQuestCheck/DrawerQuestCheck';
// import { DrawerOffchainToken } from '../pages/TradingPage/TradingTokenPage/TradingTokenPage';
// import { DrawerTradingCreate } from '../pages/TradingCreatePage/TradingCreatePage';
import { DrawerTradingCreateConfirm } from '../pages/TradingCreatePage/DrawerTradingCreateConfirm/DrawerTradingCreateConfirm';
import { DrawerTradingTransactionSuccess } from '../pages/TradingTokenPage/DrawerTradingTransaction/DrawerTradingTransactionSuccess';
import { DrawerTradingTransactionError } from '../pages/TradingTokenPage/DrawerTradingTransaction/DrawerTradingTransactionError';
import { DrawerTradingComingsoon } from '../pages/TradingTokenPage/DrawerTradingComingSoon/DrawerTradingComingsoon';
import { DrawerTradingTransactionConfirm } from '../pages/TradingTokenPage/DrawerTradingTransactionConfirm/DrawerTradingTransactionConfirm';
import { DrawerTradingSlippage } from '../pages/TradingTokenPage/DrawerTradingSlippage/DrawerTradingSlippage';
import { DrawerTradingCreationSuccess } from '../pages/TradingTokenPage/DrawerTradingTransaction/DrawerTradingCreationSuccess';
import { DrawerS1 } from '../pages/TradingTokenPage/DrawerS1/DrawerS1';
// import { DrawerQuestPromo } from './drawers/DrawerQuestPromo';
import { DrawerTiktokBuySell } from '../pages/TiktokPage/DrawerTiktokBuySell/DrawerTiktokBuySell';
import { DrawerFollow } from './drawers/DrawerFollow';
import { DrawerTiktokDetails } from '../pages/TiktokPage/DrawerTiktokDetails/DrawerTiktokDetails';
import { DrawerTiktokTransactions } from '../pages/TiktokPage/DrawerTiktokTransactions/DrawerTiktokTransactions';
import { DrawerBoosterDetails } from '../pages/Shop/DrawerBoosterDetails';
import { DrawerTiktokHolders } from '../pages/TiktokPage/DrawerTiktokHolders/DrawerTiktokHolders';
import { DrawerGenericError } from './drawers/DrawerGenericError';
import { DrawerGenericSuccess } from './drawers/DrawerGenericSuccess';
import { DrawerTradingWarning } from '../pages/TradingTokenPage/DrawerTradingWarning/DrawerTradingWarning';
import { DrawerTiktokGameOver } from '../pages/TiktokPage/DrawerTiktokGameOver/DrawerTiktokGameOver';
import { DrawerTiktokInvite } from '../pages/TiktokPage/DrawerTiktokInvite/DrawerTiktokInvite';
import { tests } from '../../replicant/ruleset';
import { DrawerFtueShare } from '../ftue/DrawerFtueShare/DrawerFtueShare';
import { DrawerUserMemeGiftBack } from './drawers/DrawerUserMemeGiftBack';

const drawers = {
  drawerFtueShare: DrawerFtueShare,
  drawerMemeGiftBack: DrawerUserMemeGiftBack,

  generic: DrawerGeneric,
  criticalError: DrawerCriticalError,
  dailyAirdrop: DrawerDailyAirdrop,
  dailyReward: DrawerDailyReward,
  errorGeneric: DrawerGenericError,
  successGeneric: DrawerGenericSuccess,
  /**
   * @deprecated Tutorial needs to be migrated to use questCheck, for now use `earnChecker` for the tutorial
   */
  earnChecker: DrawerEarnCheck,
  inviteFriend: DrawerInviteFriend,
  leaderboard: DrawerLeaderboard,
  outOfEnergy: DrawerOutOfEnergy,
  rewardSummary: DrawerRewardsSummary,
  welcome: DrawerWelcome,
  boosterDetails: DrawerBoosterDetails,
  minePromo: DrawerPowerUpCardPromo,
  mineGiftReceived: DrawerPowerUpCardGiftReceived,
  mineGiftExpired: DrawerPowerUpGiftExpired,
  mineCardReward: DrawerPowerUpEarn,
  mineBonusInfo: DrawerPowerUpInfo,
  mineCardDetails: DrawerPowerUp,
  friendsDetails: DrawerFriendsDetails,
  createTeam: CreateTeamDetails,
  multipleGifts: DrawerMultipleGifts,
  questCheck: DrawerQuestCheck,
  // questPromo: DrawerQuestPromo,
  follow: DrawerFollow,

  drawerTradingCreateConfirm: DrawerTradingCreateConfirm,
  drawerTradingTransactionConfirm: DrawerTradingTransactionConfirm,
  drawerTradingCreationSuccess: DrawerTradingCreationSuccess,
  drawerTradingTransactionSuccess: DrawerTradingTransactionSuccess,
  drawerTradingTransactionError: DrawerTradingTransactionError,
  drawerTradingComingSoon: DrawerTradingComingsoon,
  drawerTradingSlippage: DrawerTradingSlippage,
  drawerTradingWarning: DrawerTradingWarning,

  drawerS1: DrawerS1,

  drawerTiktokBuySell: DrawerTiktokBuySell,
  drawerTiktokDetails: DrawerTiktokDetails,
  drawerTiktokTransactions: DrawerTiktokTransactions,
  drawerTiktokHolders: DrawerTiktokHolders,
  drawerTiktokGameOver: DrawerTiktokGameOver,
  drawerTiktokInvite: DrawerTiktokInvite,
};

export type Drawers = keyof typeof drawers;

const nonTikTokDrawers: Drawers[] = [
  // 'generic',
  // 'criticalError',
  'dailyAirdrop',
  // 'dailyReward',
  // 'errorGeneric',
  // 'successGeneric',

  // 'earnChecker',
  // 'inviteFriend',
  'leaderboard',
  'outOfEnergy',
  'rewardSummary',
  'welcome',
  'boosterDetails',
  'minePromo',
  'mineGiftReceived',
  'mineGiftExpired',
  'mineCardReward',
  'mineBonusInfo',
  'mineCardDetails',
  // 'friendsDetails',
  'createTeam',
  'multipleGifts',
  // 'questCheck',
  // 'follow',

  // 'drawerTradingCreateConfirm',
  // 'drawerTradingTransactionConfirm',
  // 'drawerTradingCreationSuccess',
  // 'drawerTradingTransactionSuccess',
  // 'drawerTradingTransactionError',
  // 'drawerTradingComingSoon',
  // 'drawerTradingSlippage',
  // 'drawerTradingWarning',

  // 'drawerS1',

  // 'drawerTiktokBuySell',
  // 'drawerTiktokDetails',
  // 'drawerTiktokTransactions',
  // 'drawerTiktokHolders',
  // 'drawerTiktokGameOver',
  // 'drawerTiktokInvite',
];

type AnimState = 'in' | 'out' | 'hidden';

export const Drawers = () => {
  useAppUpdates({
    id: 'Drawer',
    listener: app.ui.drawer.view.attachEventListener(),
  });

  const { visible, data } = app.ui.drawer.view;

  const onClose = () => {
    app.ui.drawer.close();
  };

  const backdropClose = () => {
    if (data?.skipBackdropClose) {
      return;
    }
    app.ui.drawer.close();
  };

  const Component = drawers[data?.id || 'generic'];

  const [state, setState] = useState<AnimState>('hidden');

  useEffect(() => {
    if (visible) {
      setState('in');
    } else if (state === 'in') {
      setState('out');
      setTimeout(() => {
        setState('hidden');
      }, 250);
    }
  }, [visible]);

  const ruleset = app.replicant?.state.ruleset;
  const bucketId = ruleset.abTests[tests.TEST_TIKTOK_ONLY]?.bucketId;
  if (
    bucketId === 'tiktok_only_experience' &&
    data?.id &&
    nonTikTokDrawers.includes(data?.id)
  ) {
    app.ui.drawer.close();
    return;
  }

  // note: this will screw the drawer transition out
  // if (state === 'hidden') {
  //   return;
  // }

  return (
    <div className={`modal-drawer ${data?.id} ${state}`}>
      {/* backdrop */}
      <div
        className={`modal-drawer-backdrop ${state} ${data?.noModal} ${data?.skipBackdropClose}`}
        onClick={backdropClose}
      ></div>

      {/* box */}
      <div
        className={`modal-drawer-box ${state}`}
        style={{
          ...data?.style,
          paddingBottom: isTelegramWebview() ? '36px' : '24px',
        }}
      >
        {/* close button */}
        {!data?.hideClose && (
          <img
            className="modal-drawer-button-close"
            src={assets.button_x}
            onClick={onClose}
          />
        )}
        <Component />
      </div>
    </div>
  );
};
